import './apps/navigation';
import '../website/apps/footer.js';
import minimal from '../website/apps/minimal';
import landingPage from './apps/landingPage';
import { provisionApp, registerGlobalComponents } from '../Vue/Global';

const requireContextComponents = require.context('../components', false, /\.vue$/i);
const requireContextWebsiteComponents = require.context('../website/components', true, /\.vue$/i);

const app = $app.register({
    minimal,
    landingPage,
}).createApp();

provisionApp(app);

registerGlobalComponents(app, [
    requireContextComponents,
    requireContextWebsiteComponents,
]);

app.mount('#app');
