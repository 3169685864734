import { createApp } from 'vue';
import SvgVue from 'svg-vue3';
import VueClickAway from 'vue3-click-away';
import VIcon from '../../components/VIcon';

const app = createApp({
    data() {
        return {
            logos: {},
            scrollY: 0,
            navVisible: false,
        };
    },

    methods: {
        onScroll() {
            this.scrollY = window.scrollY;
        },
        toggleNav() {
            this.navVisible = !this.navVisible;
        },
    },

    computed: {
        headerLogo() {
            return this.isTransparent ? this.logos.darkMode : this.logos.default;
        },
        isTransparent() {
            return this.scrollY < 1;
        },
    },

    mounted() {
        this.debouncedOnScroll = _.debounce(this.onScroll, 25);
        window.addEventListener('scroll', this.debouncedOnScroll);

        this.logos = window.$logos;
        this.isHomepage = window.$isHomepage;
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.debouncedOnScroll);
    },
});

app.use(SvgVue);
app.use(VueClickAway);
app.component('VIcon', VIcon);
app.mount('#landing-header');
