import { createApp } from 'vue';

export default function () {
    return createApp({
        methods: {
            goToUrl(value) {
                let url = value.target.selectedOptions[0].value;

                if (!url.startsWith('http')) {
                    url = '/' + url;
                }

                window.location = url;
            },
        },
    });
}
